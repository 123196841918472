import 'jquery.role/lib/jquery.role'
// import 'bootstrap-sass'
import 'marquee'
import 'lity'

import FloatLabel from 'FloatLabel'
import DataBehaviorCollector from 'DataBehaviorCollector'

const ready = function () {
  new FloatLabel('@indexForm').init()
  new DataBehaviorCollector('@container')

  cropText()
  applyLazyLoad()

  $('.marquee').marquee({
    duration: 50000,
    startVisible: true,
    duplicated: true
  })
}

const cropText = function () {
  const size = 120
  const blogContent = $('@cropText')
  const text = blogContent.text()

  if (text.length > size)
    blogContent.text(`${text.slice(0, size) } ...`)
}

const applyLazyLoad = function () {
  changeBgAttributes('.hero_widget')
  changeBgAttributes('.target_group')

  const lazyLoadInstance = new LazyLoad({
    elements_selector: '.lazy'
  })

  lazyLoadInstance.update()
}

const changeBgAttributes = function (selector) {
  const background = initBackground(selector)

  $(selector).attr('data-bg', background)
}

const initBackground = function (selector) {
  if ($(window).width() > 960)
    return $(selector).data('bg-l')
   else
    return $(selector).data('bg-s')
}

const $navbarBurger = $('@navbarBurger')
const $darkBg = $('@darkBg')
const $navbarNav = $('@navbarNav')

$navbarBurger.click(function () {
  $(this).toggleClass('active')
  $navbarNav.toggleClass('active')
  $darkBg.toggleClass('active')
  $('.body').toggleClass('active')
  return false
})

$darkBg.click(function () {
  $(this).removeClass('active')
  $navbarNav.removeClass('active')
  $navbarBurger.removeClass('active')
  $('.body').removeClass('active')
})

$(document).ready(ready)
